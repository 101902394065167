import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { useState, type FunctionComponent } from 'react';
import Slider from 'react-slick';
import { CarCardImageNegotiation } from '~/components/shared/CarCardImage/CarCardImageNegotiation';
import { CarCardImageNormal } from '~/components/shared/CarCardImage/CarCardImageNormal';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { useAnalytics, useAuth, useFavorite } from '~/hooks';
import {
  useDeliveryAddressContext,
  useErrorFlashContext,
  useInfiniteScrollContext,
  useLineFavoriteModalContext } from
'~/providers';
import styles from '~/styles/page/cars/carsSearch/one-column-car-card.module.css';
import "~/styles/shared/react-slick.css?__remix_sideEffect__";
import { isNewPublished, priceNumber, priceText, shortCarModelYear } from '~/utils/carStock';

const blockClass = 'one-column-car-card';

const OneColumnCardCarStockFragment = graphql(`
  fragment OneColumnCarCard_carStock on CarStock {
    id
    ulid
    carModelYear
    mileageKm
    makeName
    carModelName
    gradeName
    totalPaymentAmount
    salesStatus
    publishedAt
    imageUrls
    oneClickContractCounts
    landTransportCosts {
      cost
      prefectureCode
    }
    promotion {
      id
      catchCopy
      staffComment
      postedBy {
        id
        imageUrl
      }
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof OneColumnCardCarStockFragment>;
};
export const OneColumnCarCard: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(OneColumnCardCarStockFragment, props.carStock);
  const { user } = useAuth();
  const { favorite } = useFavorite();
  const { open: openLineFavoriteModal } = useLineFavoriteModalContext();
  const { open: openErrorFlash } = useErrorFlashContext();
  const location = useLocation();
  const { sendTrackEvent } = useAnalytics();
  const { storeElementId } = useInfiniteScrollContext();
  const { prefectureCode } = useDeliveryAddressContext();
  const [swiping, setSwiping] = useState(false);
  const navigate = useNavigate();
  const favoritedCarStockIds = user?.favoritedCarStocks?.map((favoritedCarStock) => favoritedCarStock.id) || [];
  const isFavorited = favoritedCarStockIds.includes(carStock.id);
  const elementId = `car-stock-${carStock.ulid}`;
  const landTransportCost = prefectureCode ?
  carStock.landTransportCosts?.find((cost) => cost.prefectureCode === prefectureCode)?.cost :
  undefined;
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: () => setSwiping(true),
    afterChange: () => setSwiping(false)
  };

  const handleClickFavorite = async () => {
    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'large-car-card'
    });

    if (isAuthError) {
      openLineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const handleClickCard = () => {
    storeElementId(location.key, elementId);
    sendTrackEvent('click_large_car_card', { car_stock_id: carStock.id });
    if (liff.isInClient()) {
      navigate(`/cars/${carStock.ulid}`);
    } else {
      window.open(`/cars/${carStock.ulid}`);
    }
  };
  return (
    <div
      className={[styles[blockClass], carStock.salesStatus === 'CLOSED' ? styles['is-soldout'] : ''].join(' ')}
      id={elementId}>

      <div className={styles[`${blockClass}__image`]}>
        <Slider
          {...sliderSettings}
          dotsClass={`${blockClass}__slick-dots slick-dots ${carStock.oneClickContractCounts ? 'negotiation' : ''}`}>

          {carStock.imageUrls?.slice(0, 2).map((imageUrl, index) => {
            const carCardImage = {
              isFavorite: isFavorited,
              handleFavorite: handleClickFavorite,
              src: imageUrl,
              alt: `${carStock.carModelName} ${carStock.gradeName}`
            };
            return (
              <picture
                key={index}
                className={styles[`${blockClass}__image-picture`]}
                onClick={() => {
                  if (swiping) return;
                  handleClickCard();
                }}>

                {carStock.oneClickContractCounts ?
                <CarCardImageNegotiation count={carStock.oneClickContractCounts} size='md' {...carCardImage} /> :

                <CarCardImageNormal {...carCardImage} />
                }
              </picture>);

          })}
        </Slider>
        <div className={styles[`${blockClass}__image-label`]}>
          {carStock.salesStatus === 'CLOSED' ?
          <p className={styles[`${blockClass}__image-soldout`]}>SOLD OUT</p> :

          isNewPublished(carStock.publishedAt) && <p className={styles[`${blockClass}__image-new`]}>NEW</p>
          }
        </div>
      </div>
      <div className={styles[`${blockClass}__content`]} onClick={handleClickCard}>
        <div className={styles[`${blockClass}__name`]}>
          <p className={styles[`${blockClass}__name-maker`]}>{[carStock.makeName]}</p>
          <p className={styles[`$ a{blockClass}__name-model`]}>
            {[carStock.carModelName, carStock.gradeName].join(' ')}
          </p>
        </div>

        <div className={styles[`${blockClass}__status`]}>
          <div className={styles[`${blockClass}__features`]}>
            <div className={styles[`${blockClass}__features-wrapper`]}>
              <div className={styles[`${blockClass}__features-item`]}>
                <span className={styles[`${blockClass}__features-item__icon`]}>年</span>
                <span className={styles[`${blockClass}__features-item__text`]}>
                  {shortCarModelYear(carStock.carModelYear) || 'ー'}
                </span>
              </div>
              <div className={styles[`${blockClass}__features-item`]}>
                <span className={styles[`${blockClass}__features-item__icon`]}>走</span>
                <span className={styles[`${blockClass}__features-item__text`]}>
                  {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}
                  km
                </span>
              </div>
            </div>
          </div>
          <p className={styles[`${blockClass}__image-price`]}>
            {carStock.totalPaymentAmount ?
            <>
                <span className={styles[`${blockClass}__image-price__text`]}>総額</span>
                <span className={styles[`${blockClass}__image-price__number`]}>
                  {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
                </span>
                {priceText(carStock.totalPaymentAmount, landTransportCost)}
              </> :

            <span>価格はお問い合わせください</span>
            }
          </p>
        </div>
        {carStock.promotion &&
        <div className={styles[`${blockClass}__comment`]}>
            <div className={styles[`${blockClass}__comment-staff`]}>
              <div className={styles[`${blockClass}__comment-staff__icon`]}>
                <img src={carStock.promotion.postedBy?.imageUrl || '/images/pictures/no_image.png'} alt='icon' />
              </div>
              {carStock.promotion.staffComment &&
            <p className={styles[`${blockClass}__comment-staff__text`]}>
                  <span className={styles[`${blockClass}__comment-title`]}>{carStock.promotion.catchCopy}</span>
                  <span>{carStock.promotion.staffComment}</span>
                </p>
            }
            </div>
          </div>
        }
      </div>
    </div>);

};