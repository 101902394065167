import { useDisclosure } from '@mantine/hooks';
import { useLocation, useNavigate } from '@remix-run/react';
import type { ComponentProps, FunctionComponent } from 'react';
import { useState } from 'react';
import { LoginLink } from '~/components/parts';
import { ShareModal } from '~/components/shared';
import { SubmitButton } from '~/components/shared/SubmitButton/SubmitButton';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { CarSalesStatusEnum, type CarFooter_CarStockFragment } from '~/gql/generated/graphql';
import { useAnalytics, useAuth, useFavorite } from '~/hooks';
import { useDeliveryAddressContext, useErrorFlashContext, useLineFavoriteModalContext } from '~/providers';
import styles from '~/styles/page/cars/carsShow/car-footer.module.css';
import { type CarStockLandTransportCostType } from '~/types/landTransportCost';
import { priceNumber, priceText } from '~/utils/carStock';
import { isSsr } from '~/utils/ssr';
import { OneClickContractButton } from './OneClickContractButton';

const blockClass = 'car-footer';

type PricePopupProps = {
  opened: boolean;
  close: () => void;
  salesPrice?: number | null;
  otherPrice?: number | null;
  landTransportCost?: number;
};

const PricePopup: FunctionComponent<PricePopupProps> = (props) => {
  const { opened, close, salesPrice, otherPrice, landTransportCost } = props;

  if (!opened) {
    return <></>;
  }

  const formattedPrice = (price?: number | null) => {
    if (!price) {
      return 'ASK';
    }

    return `${price >= 10000 ? Math.floor(price / 1000) / 10 : price}${price >= 10000 ? '万' : ''}円`;
  };

  return (
    <div className={styles[`${blockClass}__price-question__popup`]}>
      <div className={styles[`${blockClass}__price-question__popup-header`]}>
        <p>総額内訳（税込）</p>
        <img
          src='/images/icons/ic_close.svg'
          alt='close'
          onClick={(e) => {
            e.stopPropagation();
            close();
          }}
          className={styles[`${blockClass}__price-question__popup-close`]} />

      </div>
      <div className={styles[`${blockClass}__price-question__popup-item`]}>
        <p>車両本体価格</p>
        <p>{formattedPrice(salesPrice)}</p>
      </div>
      <div className={styles[`${blockClass}__price-question__popup-item`]}>
        <p>諸費用</p>
        <p>{formattedPrice(otherPrice)}</p>
      </div>
      <div className={styles[`${blockClass}__price-question__popup-item`]}>
        <p>配送料</p>
        <p>{landTransportCost ? formattedPrice(landTransportCost) : '-'}</p>
      </div>
    </div>);

};

const ButtonContents = ({ text }: {text: string;}) => {
  return (
    <>
      <img src={'/images/icons/ic_line.svg'} className={styles[`${blockClass}__button-icon`]} alt='line' />
      <span className={styles[`${blockClass}__button-text`]}>{text}</span>
    </>);

};

const CarFooterCarStockFragment = graphql(`
  fragment CarFooter_carStock on CarStock {
    id
    ulid
    makeName
    carModelName
    gradeName
    totalPaymentAmount
    salesPrice
    otherPrice
    salesStatus
    landTransportCosts {
      cost
      prefectureCode
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarFooterCarStockFragment>;
  isFavorited: boolean;
  title: string;
};

export const CarFooter: FunctionComponent<Props> = (props) => {
  const { isFavorited, title } = props;
  const carStock = useFragment(CarFooterCarStockFragment, props.carStock) as CarFooter_CarStockFragment &
  CarStockLandTransportCostType;
  const { isAuthenticated, user, fetching } = useAuth();
  const inquiredCarStockIds =
  user?.inquiries?.map((inquiry) => inquiry.carStocks?.map((carStock) => carStock.id) || [])?.flat() || [];
  const isInquired = inquiredCarStockIds.includes(carStock.id);
  const { open: openlineFavoriteModal } = useLineFavoriteModalContext();
  const { open: openErrorFlash } = useErrorFlashContext();
  const [shareModalOpened, { open: shareModalOpen, close: shareModalClose }] = useDisclosure(false);
  const [pricePopupOpened, { open: pricePopupOpen, close: pricePopupClose }] = useDisclosure(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { favorite } = useFavorite();
  const { sendTrackEvent } = useAnalytics();
  const currentUrl = !isSsr ? window.location.href : '';
  const { prefectureCode } = useDeliveryAddressContext();
  const landTransportCost = prefectureCode ?
  carStock.landTransportCosts?.find((cost) => cost.prefectureCode === prefectureCode)?.cost :
  undefined;
  const location = useLocation();
  const searchConditionPath = `/search-condition?redirectPath=${location.pathname}&makeName=${carStock.makeName}&carModelName=${carStock.carModelName}`;
  const buttonPath = searchConditionPath;
  const isClosed = carStock.salesStatus === CarSalesStatusEnum.Closed;

  const handleFavorite = async () => {
    const { isAuthError, isSuccess, mutateType } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'car-show-footer'
    });

    if (isAuthError) {
      openlineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }

    if (mutateType === 'create') {
      openPopup();
    }
  };

  const openPopup = () => {
    setIsOpenPopup(true);
    window.setTimeout(() => {
      setIsOpenPopup(false);
    }, 1000);
  };

  const handleClickConsultation = () => {
    sendTrackEvent('click_consultation_button', { car_stock_id: carStock.id });
  };

  const navigate = useNavigate();

  if (fetching) return null;

  return (
    <>
      <div className={styles[blockClass]}>
        {isInquired && <span className={styles[`${blockClass}__inquired`]}>お問合せ済</span>}
        <div className={styles[`${blockClass}__row`]}>
          <div className={styles[`${blockClass}__car`]}>
            <div className={styles[`${blockClass}__car-header`]}>
              <p className={styles[`${blockClass}__car-maker`]}>{carStock.makeName}</p>
              <p className={styles[`${blockClass}__car-name`]}>{carStock.carModelName}</p>
            </div>
            <p className={styles[`${blockClass}__car-grade`]}>{carStock.gradeName}</p>
          </div>
          <div className={styles[`${blockClass}__right-area`]}>
            <div className={styles[`${blockClass}__icon-row`]}>
              <div className={styles[`${blockClass}__like`]}>
                {isOpenPopup &&
                <div className={styles[`${blockClass}__like-popup`]}>
                    <img
                    src={'/images/icons/ic_favorite_filled.svg'}
                    className={styles[`${blockClass}__like-popup__image`]}
                    alt='favorite' />

                    <p className={styles[`${blockClass}__like-popup__text`]}>お気に入りに追加しました</p>
                  </div>
                }
                <img
                  src={`/images/icons/${isFavorited ? 'ic_favorite_filled.svg' : 'ic_favorite.svg'}`}
                  alt='favorite'
                  onClick={handleFavorite}
                  className={styles[`${blockClass}__like-icon`]} />

              </div>
              <img
                src={'/images/icons/ic_share.svg'}
                className={styles[`${blockClass}__share`]}
                alt='share'
                onClick={shareModalOpen} />

            </div>
            <div className={styles[`${blockClass}__price-row`]}>
              <p className={styles[`${blockClass}__price`]}>
                {carStock.totalPaymentAmount ?
                <>
                    <span className={styles[`${blockClass}__price-number`]}>
                      {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
                    </span>
                    <span className={styles[`${blockClass}__price-text`]}>
                      {priceText(carStock.totalPaymentAmount, landTransportCost)}
                    </span>
                  </> :

                <span>価格はお問い合わせください</span>
                }
              </p>
              <div className={styles[`${blockClass}__price-question`]} onClick={pricePopupOpen}>
                <img
                  src='/images/icons/ic_question.svg'
                  alt='question'
                  width={'16'}
                  className={styles[`${blockClass}__price-image`]} />

                <PricePopup
                  opened={pricePopupOpened}
                  close={pricePopupClose}
                  salesPrice={carStock.salesPrice}
                  otherPrice={carStock.otherPrice}
                  landTransportCost={landTransportCost} />

              </div>
            </div>
          </div>
        </div>
        <div className='flex gap-3'>
          <div
            id='car-footer-consultation-button'
            className={styles[`${blockClass}__button-wrapper`]}
            onClick={handleClickConsultation}>

            {isAuthenticated ?
            <SubmitButton
              text={'新着通知登録'}
              variant='outline'
              handleClick={() => {
                navigate(buttonPath);
              }} /> :


            <NeedLogin isClosed={isClosed} redirectPath={buttonPath} />
            }
          </div>
          <OneClickContractButton
            handleClick={handleClickConsultation}
            carStockId={carStock.id}
            ulid={carStock.ulid}
            activeOneClickContracts={user?.activeOneClickContracts} />

        </div>
      </div>
      <ShareModal opened={shareModalOpened} close={shareModalClose} url={currentUrl} title={title} />
    </>);

};

type NeedLoginProps = {
  isClosed: boolean;
} & Pick<ComponentProps<typeof LoginLink>, 'redirectPath'>;
const NeedLogin = ({ isClosed, redirectPath }: NeedLoginProps) => {
  return (
    <LoginLink className={isClosed ? styles[`${blockClass}__button`] : ''} redirectPath={redirectPath}>
      {isClosed ?
      <ButtonContents text={'似ている車両をリクエストする'} /> :

      <SubmitButton text={'新着通知登録'} variant='outline' handleClick={() => {}} />
      }
    </LoginLink>);

};