import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';

import styles from '~/styles/page/cars/carsIndex/car-find.module.css';

const blockClass = 'car-find';

export const CarFindSection: FunctionComponent = () => {
  const finds = [
  {
    image: '/images/icons/ic_find01.svg',
    link: '/search?status=ON_SALE',
    title: '探す',
    alt: '探す'
  },
  {
    image: '/images/icons/ic_find02.svg',
    link: '/selling/',
    title: '売る',
    alt: '売る'
  },
  {
    image: '/images/icons/ic_find03.svg',
    link: '/search?status=ON_SALE&sortType=NEWER',
    title: '新着車両',
    alt: '新着車両'
  },
  {
    image: '/images/icons/ic_find05.svg',
    link: '/magazine',
    title: '知る',
    alt: '知る',
    isExternal: true
  },
  {
    image: '/images/icons/ic_find07.svg',
    link: '/guide',
    title: '初めての方',
    alt: '初めての方'
  },
  {
    image: '/images/icons/ic_find08.svg',
    link: '/support',
    title: '保証',
    alt: '保証'
  },
  {
    image: '/images/icons/ic_find06.svg',
    link: '/ai_nakano',
    title: 'AIに相談',
    alt: 'AIに相談'
  }];


  return (
    <div className={styles[blockClass]}>
      <h2 className={styles[`${blockClass}__title`]}>目的別に見つける</h2>
      <div className={styles[`${blockClass}__list`]}>
        {finds.map((find, index) =>
        <div className={styles[`${blockClass}__item`]} key={index}>
            {find.isExternal ?
          <a href={find.link} className={styles[`${blockClass}__item-link`]}>
                <img src={find.image} alt={find.alt} className={styles[`${blockClass}__item-image`]} />
              </a> :

          <Link to={find.link} className={styles[`${blockClass}__item-link`]}>
                <img src={find.image} alt={find.alt} className={styles[`${blockClass}__item-image`]} />
              </Link>
          }
            <p>{find.title}</p>
          </div>
        )}
        {/* remove after released AI nakano */}
        {/* <div className={styles[`${blockClass}__item`]} /> */}
      </div>
    </div>);

};