import { Link } from '@remix-run/react';
import { useState, type FunctionComponent, type ReactNode } from 'react';
import { TrackableLink } from '~/components/parts';
import { Breadcrumb, MediaSection } from '~/components/shared';
import styles from '~/styles/page/guide/guide-index.module.css';

const blockClass = 'guide';

type FapProps = {
  title: string;
  text: string | ReactNode;
};

const Faq: FunctionComponent<FapProps> = ({ title, text }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles[`${blockClass}__faq-item`]} onClick={() => setOpen(!open)}>
      <div className={styles[`${blockClass}__faq-head`]}>
        <h3 className={styles[`${blockClass}__faq-title`]}>{title}</h3>
      </div>
      {open &&
      <div className={styles[`${blockClass}__faq-body`]}>
          <p className={styles[`${blockClass}__faq-text`]}>{text}</p>
        </div>
      }
    </li>);

};

const LineBanner: FunctionComponent = () => {
  return (
    <p className={styles[`${blockClass}__line-banner`]}>
      <TrackableLink
        to='/add_line_friend?inflow_source_message=notifynew'
        analyticsData={{ eventName: 'click_registration_line_banner' }}>

        <img src={'/images/banners/line_friend.png'} alt='' />
      </TrackableLink>
    </p>);

};

export const GuideIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='バディカダイレクトとは？' />
      <h1 className='headline1'>バディカダイレクトとは？</h1>
      <div className={styles[`${blockClass}__message`]}>
        <p className={styles[`${blockClass}__message-title`]}>
          車の売買を
          <br />
          もっと自由に、なめらかに
        </p>
        <p className={styles[`${blockClass}__message-text`]}>
          バディカダイレクトは、車に関わる全てのお客様の生活を豊かにしていく為、圧倒的な努力と情熱をもって社会に貢献していきます。
        </p>
        <div className={styles[`${blockClass}__message-name`]}>
          <img src={'/images/guide/sign_nakano.png'} alt='代表取締役社長 中野優作' />
        </div>
      </div>

      <ul className={styles[`${blockClass}__point`]}>
        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>簡単</p>
          <h2 className={styles[`${blockClass}__point-title`]}>ネットで買って自宅に届く</h2>
          <h3 className={styles[`${blockClass}__point-subTitle`]}>
            車選びのご相談から納車まで
            <br />
            すべてがオンラインで
            <br />
            らくらく完結
          </h3>
          <p className={styles[`${blockClass}__point-text`]}>
            LINEで相談しながら納得してご購入いただけます。
            <br />
            その後のお手続きも簡単です。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/guide/img_point_1.png'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>ワクワク</p>
          <h2 className={styles[`${blockClass}__point-title`]}>特別な1台</h2>
          <h3 className={styles[`${blockClass}__point-subTitle`]}>
            BUDDICAトップバイヤーが厳選。
            <br />
            品質・プライス基準をクリアした
            <br />
            クルマをセレクト
          </h3>
          <p className={styles[`${blockClass}__point-text`]}>
            お客様が最高の一台を見つけるため掲載車両以外でもご希望に対し、優秀なスタッフが親身になってご提案します。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/guide/img_point_2.png'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>品質</p>
          <h2 className={styles[`${blockClass}__point-title`]}>完全な透明性</h2>
          <h3 className={styles[`${blockClass}__point-subTitle`]}>
            手続きのプロセスと
            <br />
            ステータスを完全見える化
            <br />
            全車両鑑定書付きで安心
          </h3>
          <p className={styles[`${blockClass}__point-text`]}>
            マイページから納車までの手続きの状況を常に確認できます。車の修理歴もしっかり提示します。
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/guide/img_point_3.png'} alt='' />
          </div>
        </li>

        <li className={styles[`${blockClass}__point-item`]}>
          <p className={styles[`${blockClass}__point-label`]}>アフターも安心</p>
          <h2 className={styles[`${blockClass}__point-title`]}>おウチで整備＆洗車</h2>
          <h3 className={styles[`${blockClass}__point-subTitle`]}>
            整備士が定期的にご自宅に伺って
            <br />
            安全・快適なカーライフを
            <br />
            サポートします
          </h3>
          <p className={styles[`${blockClass}__point-text`]}>
            プランに加入者限定。整備士がご自宅にお伺いして、お車の状態チェック・定期的なメンテナンス +
            洗車を実施します。
          </p>
          <p className={styles[`${blockClass}__support-link`]}>
            <a href='/pdf/ouchi_pack.pdf' className='c-textLink'>
              アフターサポートについて
            </a>
          </p>
          <div className={styles[`${blockClass}__point-img`]}>
            <img src={'/images/guide/img_point_4.png'} alt='' />
          </div>
          <p className={styles[`${blockClass}__point-noteText`]}>※BUDDICAの公式パートナーSeibiiが事業を提供します</p>
        </li>
      </ul>

      <h2 className={styles[`${blockClass}__title`]} id='support'>
        バディカダイレクトの充実サポート
      </h2>
      <p className={styles[`${blockClass}__titleCaption`]}>中古車を購入するときの不安を解決します！</p>

      <p className={styles[`${blockClass}__titleSub`]}>購入者全員対象</p>

      <ul className={styles[`${blockClass}__support`]}>
        <li className={styles[`${blockClass}__support-item`]}>
          <h3 className={styles[`${blockClass}__support-title`]}>
            <span>
              30日間
              <br />
              返金保証
            </span>
          </h3>
        </li>

        <li className={styles[`${blockClass}__support-item`]}>
          <h3 className={styles[`${blockClass}__support-title`]}>
            <span>
              初期不良
              <br />
              無償対応
            </span>
          </h3>
        </li>

        <li className={styles[`${blockClass}__support-item`]}>
          <h3 className={styles[`${blockClass}__support-title`]}>
            <span>
              1か月
              <br />
              点検
            </span>
          </h3>
        </li>
      </ul>

      <p className={styles[`${blockClass}__support-link`]}>
        <a href='/support' className='c-textLink'>
          詳しくみる
        </a>
      </p>

      <h2 className={styles[`${blockClass}__title`]}>購入の流れ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>車がお客さまのお手元に届くまで</p>

      <ol className={styles[`${blockClass}__step`]}>
        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>1</p>
          <div className={styles[`${blockClass}__step-img`]}>
            <img src={'/images/guide/icon_step_1.svg'} alt='' />
          </div>
          <div className={styles[`${blockClass}__step-inner`]}>
            <h3 className={styles[`${blockClass}__step-title`]}>気になる車のページからLINEでご相談</h3>
            <p className={styles[`${blockClass}__step-text`]}>
              お気軽にご相談ください。
              <br />
              複数車両まとめての問い合わせも可能。
            </p>
            <ul className={styles[`${blockClass}__step-point`]}>
              <li>チャットも会話もOK</li>
              <li>グループでの会話もOK</li>
            </ul>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>2</p>
          <div className={styles[`${blockClass}__step-img`]}>
            <img src={'/images/guide/icon_step_2.svg'} alt='' />
          </div>
          <div className={styles[`${blockClass}__step-inner`]}>
            <h3 className={styles[`${blockClass}__step-title`]}>お申し込み</h3>
            <p className={styles[`${blockClass}__step-text`]}>車の申込手続きを簡単に完了し、予約確定。</p>
            <ul className={styles[`${blockClass}__step-point`]}>
              <li>LINEでお申し込み</li>
              <li>電子契約</li>
            </ul>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>3</p>
          <div className={styles[`${blockClass}__step-img`]}>
            <img src={'/images/guide/icon_step_3.svg'} alt='' />
          </div>
          <div className={styles[`${blockClass}__step-inner`]}>
            <h3 className={styles[`${blockClass}__step-title`]}>お支払い</h3>
            <p className={styles[`${blockClass}__step-text`]}>お支払いは安全な方法で行います。</p>
            <ul className={styles[`${blockClass}__step-point`]}>
              <li>現金</li>
              <li>ローン</li>
            </ul>
          </div>
        </li>

        <li className={styles[`${blockClass}__step-item`]}>
          <p className={styles[`${blockClass}__step-num`]}>4</p>
          <div className={styles[`${blockClass}__step-img`]}>
            <img src={'/images/guide/icon_step_4.svg'} alt='' />
          </div>
          <div className={styles[`${blockClass}__step-inner`]}>
            <h3 className={styles[`${blockClass}__step-title`]}>書類の準備</h3>
            <p className={styles[`${blockClass}__step-text`]}>
              必要な書類はBUDDICAから送付いたします。それらの書類に必要事項を記入して、手続きを進めてください。
            </p>
            <ul className={styles[`${blockClass}__step-point`]}>
              <li>印鑑証明書</li>
              <li>車庫証明書</li>
            </ul>
          </div>
        </li>

        <li className={styles[`${blockClass}__stepDone`]}>
          <div className={styles[`${blockClass}__stepDone-inner`]}>
            <div className={styles[`${blockClass}__stepDone-img`]}>
              <img src={'/images/icons/ic_check_circle.svg'} alt='' />
            </div>
            <h3 className={styles[`${blockClass}__stepDone-title`]}>お手続き完了</h3>
            <p className={styles[`${blockClass}__stepDone-text`]}>
              点検・車検手続き、その他納車準備をBUDDICAが進めます
            </p>
            <ul className={styles[`${blockClass}__stepDone-point`]}>
              <li>マイページからいつでも進捗確認</li>
            </ul>
          </div>
        </li>

        <li className={styles[`${blockClass}__stepDelivery`]}>
          <p className={styles[`${blockClass}__stepDelivery-text`]}>お手続き完了から約5～14日で</p>
          <h3 className={styles[`${blockClass}__stepDelivery-title`]}>ご希望の場所へ納車します</h3>
          <div className={styles[`${blockClass}__stepDelivery-img`]}>
            <img src={'/images/guide/icon_step_5.svg'} alt='' />
          </div>
          <ul className={styles[`${blockClass}__stepDelivery-point`]}>
            <li>納車日から6ヶ月間の保証がつきます</li>
          </ul>
          <p className={styles[`${blockClass}__stepDelivery-caution`]}>※プラン加入者のみ</p>
        </li>
      </ol>

      <LineBanner />

      <div className={styles[`${blockClass}__3step`]}>
        <h4 className={styles[`${blockClass}__3step-heading`]}>クルマを選ぶ前に知っておきたい！</h4>
        <p className={styles[`${blockClass}__3step-description`]}>
          初めてのクルマ選びの不安を解消するポイントを紹介したPDFをLINEにて配布中。
        </p>
        <div className={styles[`${blockClass}__3step-img`]}>
          <img src={'/images/guide/img_guide_3step.jpg'} alt='' width={'100%'} />
        </div>
        <p className={styles[`${blockClass}__3step-button`]}>
          <TrackableLink to='/add_line_friend' analyticsData={{ eventName: 'click_download_3step_document_banner' }}>
            LINEから資料をダウンロード
          </TrackableLink>
        </p>
      </div>

      <h2 className={styles[`${blockClass}__title`]}>バディカダイレクトのスタッフ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>あなたのカーライフに寄り添います</p>
      <p className={styles[`${blockClass}__staff-caption`]}>
        あなたのカーライフを特別なものに変えるため、心を込めてサポートいたします。お気軽にご相談ください！
      </p>
      <ul className={styles[`${blockClass}__staff`]}>
        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/nakano'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/nakano/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>中野 優作</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                新車を作らず、愛車を作ろう。我々BUDDICAは、どこよりも「透明性」の高いサービスをお約束します。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/yusa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/yusa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>由佐 朋也</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                BUDDICAの創業メンバー。お客様の未来を想像し全国のお客様に「愛車」を届けます。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/kurita'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/kurita/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>栗田 政浩</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様が最高の愛車に出会えるように全力でサポートし、楽しいクルマ選びを一緒にしていきましょう。
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳しく見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/hasegawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/hasegawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>長谷川 奨</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                お客様の理想をとことんお伺いします！！その上で最高の一台を共に拘り抜いて創造していきましょう！！
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>

        <li className={styles[`${blockClass}__staff-item`]}>
          <Link to='/member/midorikawa'>
            <div className={styles[`${blockClass}__staff-img`]}>
              <img src={'/images/members/midorikawa/list.jpg'} alt='' width={'100%'} />
            </div>
            <div className={styles[`${blockClass}__staff-inner`]}>
              <p className={styles[`${blockClass}__staff-name`]}>緑川 凱久</p>
              <p className={styles[`${blockClass}__staff-text`]}>
                あなたと一生のお付き合いする覚悟で誰よりも寄り添って一緒に考えます。楽しいクルマ探しの旅をしましょう！
              </p>
              <p className={styles[`${blockClass}__staff-link`]}>詳細を見る</p>
            </div>
          </Link>
        </li>
      </ul>
      <p className={[styles[`${blockClass}__staff-button`], 'c-button is-secondary'].join(' ')}>
        <Link to='/members/'>もっと見る</Link>
      </p>

      <h2 className={styles[`${blockClass}__title`]}>お客様からのメッセージ</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>実際にご利用された方の声を掲載</p>

      <div className={styles[`${blockClass}__reviews-item`]}>
        <div className={styles[`${blockClass}__reviews-head`]}>
          <div className={styles[`${blockClass}__reviews-img`]}>
            <img src={'/images/reviews/img_1003_ti_s.png'} alt='' />
          </div>
          <div>
            <p className={styles[`${blockClass}__reviews-date`]}>投稿日：2023/10/03</p>
            <p className={styles[`${blockClass}__reviews-name`]}>北海道 T.I様</p>
            <p className={styles[`${blockClass}__reviews-car`]}>
              <span>マツダ</span>CX-5
            </p>
          </div>
        </div>
        <p className={styles[`${blockClass}__reviews-text`]}>
          土曜日に無事に納車されました！
          <br />
          車の状態も大変満足しています、ヘッドライトも磨いて頂きありがとうございました！
          <br />
          妻と子供も喜んでいて、これから家族で出掛けるのが楽しみです。
          <br />
          今回は中古車を購入するにあたりバディカ様を…
        </p>
        <div className={styles[`${blockClass}__reviews__comment`]}>
          <p className={styles[`${blockClass}__reviews__comment-title`]}>担当者からのコメント</p>
          <p className={styles[`${blockClass}__reviews__comment-text`]}>
            この素敵なご報告、大変嬉しいです！
            <br />
            ご家族の喜びと共に、お客様にご満足いただけたことが何よりです。ビデオ通話やLINEを活用してスムーズなコミュニケーションができ、良かった点を箇条書きでまとめていただきありがとうございます。これからも信頼と安心を提供できるよう、努めてまいります。どうぞ安全運転で、また何かお力になれることがあればお知らせください。ありがとうございました！
          </p>
          <p className={styles[`${blockClass}__reviews__comment-staff`]}>
            <img src={'/images/staff/staff_yusa.jpg'} alt='' />
            由佐 朋也
          </p>
        </div>
      </div>

      <p className={[styles[`${blockClass}__user-message-button`], 'c-button is-secondary'].join(' ')}>
        <Link to='/reviews/'>もっと見る</Link>
      </p>

      <LineBanner />

      <h2 className={styles[`${blockClass}__title`]}>よくあるご質問</h2>
      <p className={styles[`${blockClass}__titleCaption`]}>ご質問いただく内容をまとめました</p>

      <ul className={styles[`${blockClass}__faq`]}>
        <Faq title='車両の価格交渉は可能ですか？' text='価格交渉については、お受けしておりません。' />
        <Faq
          title='車両の保証について教えてください。'
          text={
          <>
              保証内容は<Link to='#support'>こちらのページ</Link>に記載しています。
            </>
          } />

        <Faq title='実際の車両を見に行くことはできますか？' text='店舗に来ていただければ確認いただけます。' />
        <Faq
          title='車両の購入手続きはどうすればいいですか？'
          text='LINEから相談いただければ、見積もりや契約のご案内をします。' />

        <Faq
          title='車両ごとに配送料（陸送費）は異なりますか？'
          text='車両がある店舗、お届けの地域によって異なります。サイト上の「お届け先の送料見積もり」に郵便番号を入力いただけると正確な費用が表示されます。' />

        <Faq
          title='車両の交換や返品は可能ですか？'
          text={
          <>
              交換や返品ポリシーについては、<Link to='#support'>こちら</Link>でご確認いただけます。
            </>
          } />

        <Faq
          title='中古車についての検査や整備は行っていますか？'
          text='全ての中古車は厳格な検査と整備を受けています。' />

        <Faq
          title='車両の履歴情報や事故歴について教えてください。'
          text='各車両の履歴情報や事故歴については、LINEで相談いただいた際に詳しい車両情報をお渡ししてます。' />

        <Faq
          title='車庫証明書類を自分で手配することで費用を抑えられますか？'
          text={
          <>
              はい。可能です。
              <br />
              少しでも費用を安く済ませたい場合は車庫証明の書類を自分で取得いただくことで5,000円〜10,000円ほど節約することが可能です。
            </>
          } />

        <Faq
          title='自宅まで配送してくれますか？'
          text={
          <>
              自宅まで配送します。
              <br />
              注文時に入力いただいたご自宅住所まで配送いたします。代金のお支払い及び契約書類一式の返送後、およそ2～3週間でご自宅まで納車されます。納車日については、販売店よりお客様へご連絡させていただきます。
            </>
          } />

        <Faq
          title='車両を引き取りに行くことで配送料を削減できますか？'
          text={
          <>
              できます。
              <br />
              車両の置いてある店舗まで引き取りに行くことで配送料を削減することが可能です。
            </>
          } />

        <Faq
          title='契約にあたって、来店する必要はありますか？'
          text='来店の必要はありません。電子契約や、郵送での書類のやり取りで完結します。' />

        <Faq
          title='ローンは手続きできますか？'
          text='オンライン上でローンの手続きも可能です。スタッフにお気軽にご相談ください。' />

        <Faq title='車の買取はやっていますか？' text='はい、やっています。LINEからお問い合わせください。' />
      </ul>

      <MediaSection />
    </>);

};