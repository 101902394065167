import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/valuation-step.module.css';
import { ConversionButton } from './ConversionButton';

export const ValuationStep: FunctionComponent = () => {
  return (
    <div>
      <p className={styles.title}>査定ステップ</p>
      <p className={styles.caption}>4ステップ＆LINEで完結！</p>
      <ol className={styles.step}>
        <li className={styles.item}>
          <p className={styles.num}>1</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/valuation_step_1.svg'} alt='icon step1' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>
                たった4項目で
                <br />
                買取目安額をチェック
              </h3>
              <p className={styles.text}>
                AIを使って自動で買取目安額を算出します。中古車販売店などの業者が使用するオークションデータを活用しています。
              </p>
            </div>
          </div>
          <div className={styles.button}>
            <ConversionButton text='買取目安額を今すぐ調べる' />
          </div>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>2</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/valuation_step_2.svg'} alt='icon step2' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>愛車情報の入力</h3>
              <p className={styles.text}>
                車両画像や車検証をフォームから送信していただきます。
                <br />
                車検証は車台番号などの確認に使用します。内装・外装の傷や凹みも写真で確認します。
              </p>
            </div>
          </div>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>3</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/valuation_step_3.svg'} alt='icon step3' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>査定金額のご提示</h3>
              <p className={styles.text}>お送りいただいた情報をもとに確定した査定金額をご提示します。</p>
            </div>
          </div>
          <p className={styles.note}>
            ※<span>提示日から10日以内の引き取りが有効期限</span>になります。
          </p>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>4</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/valuation_step_4.svg'} alt='icon step4' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>契約</h3>
              <p className={styles.text}>
                査定金額にご納得いただけたら契約に進みます。
                <br />
                もちろん、査定のみでも大丈夫です。
              </p>
            </div>
          </div>
        </li>
      </ol>

      <div className={styles['bottom-button']}>
        <ConversionButton text='無料：愛車査定はこちら' />
      </div>
    </div>);

};