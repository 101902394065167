import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/feature.module.css';
import { ConversionButton } from './ConversionButton';

const Feature1: FunctionComponent = () => {
  return (
    <div className={styles.feature}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <img src='/images/selling/top_icon_smartphone.svg' alt='top icon' />
        </div>
        <p className={styles.text}>
          いつでもどこでも
          <br />
          10秒で買取目安額がわかる
        </p>
      </div>

      <div className={styles.step}>
        <div className={styles.row}>
          <div className={styles.item}>
            <div className={styles.image}>
              <img src='/images/selling/feature_step1.png' alt='feature step1' />
            </div>
          </div>
          <div className={styles.triangle}>
            <img src='/images/icons/ic_triangle_gray.svg' alt='triangle' />
          </div>
          <div className={styles.item}>
            <div className={styles.image}>
              <img src='/images/selling/feature_step2.png' alt='feature step2' />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.item}>
            <div className={styles.image}>
              <img src='/images/selling/feature_step3.png' alt='feature step3' />
            </div>
          </div>
          <div className={styles.triangle}>
            <img src='/images/icons/ic_triangle_gray.svg' alt='triangle' />
          </div>
          <div className={styles.item}>
            <div className={styles.image}>
              <img src='/images/selling/feature_step4.png' alt='feature step4' />
            </div>
          </div>
        </div>
      </div>

      <ConversionButton text='買取目安額を今すぐ調べる' />
    </div>);

};

const Feature2: FunctionComponent = () => {
  return (
    <div className={styles.feature}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <img src='/images/selling/message_icon_2.svg' alt='message_icon_2' />
        </div>
        <p className={styles.text}>面倒な電話は一切なし！</p>
      </div>
      <p>
        すべてLINEでのやり取りで完結します。お急ぎの場合や、電話で相談したいとご希望いただいた場合のみ電話のご提案をします。いきなり電話をかけて、貴重なお時間をいただくことはありません。
      </p>
    </div>);

};

// 解体が復活するまで非表示
// const Feature3: FunctionComponent = () => {
//   return (
//     <div className={styles.feature}>
//       <div className={styles.header}>
//         <div className={styles.icon}>
//           <img src='/images/selling/search.svg' alt='search' />
//         </div>
//         <p className={styles.text}>
//           どんな車も写真登録だけで
//           <br />
//           査定可能！
//         </p>
//       </div>
//       <p>
//         どんな車でも買取できる仕組みがあります。全国のバディカ店舗やバディカダイレクトを通じて買い取った車両を直接販売することで、中間マージンを削減し、どんな車でも買取が可能になっています。
//       </p>
//     </div>
//   );
// };

const Relieve: FunctionComponent = () => {
  return (
    <div className={styles.relieve}>
      <h2 className={styles['relieve-title']}>こんな方も安心してください！</h2>
      <ul className={styles.list}>
        <li className={styles.item}>ローンが残ってる</li>
        <li className={styles.item}>事故歴がある</li>
        {/* <li className={styles.item}>他社で値段がつかないと言われた</li> */}
      </ul>
      <p className={styles.text}>
        バディカダイレクトなら
        <br />
        買取可能です
      </p>
      <div className={styles.image}>
        <img src={'/images/selling/img_relieve.png'} alt='' />
      </div>
    </div>);

};

export const Feature: FunctionComponent = () => {
  return (
    <div>
      <p className={styles.title}>バディカダイレクト査定の特徴</p>
      <p className={styles.caption}>選ばれる理由</p>
      <Feature1 />
      <Feature2 />
      {/* <Feature3 /> */}
      <Relieve />
      <div className={styles['bottom-button']}>
        <ConversionButton text='無料：愛車査定はこちら' />
      </div>
    </div>);

};