import type { FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';
import { AfterStep } from './AfterStep';
import { Faq } from './Faq';
import { Feature } from './Feature';
import { ReviewSection } from './ReviewSection';
import { StaffSection } from './StaffSection';
import { Top } from './Top';
import { ValuationStep } from './ValuationStep';

export const SellingIndex: FunctionComponent = () => {
  return (
    <div>
      <Breadcrumb text='クルマを売りたい方' />
      <Top />
      <Feature />
      <ValuationStep />
      <AfterStep />
      <StaffSection />
      <ReviewSection />
      <Faq />
    </div>
  );
};
