import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { TrackableLink } from '~/components/parts';
import { Breadcrumb } from '~/components/shared';
import styles from '~/styles/page/support/support-index.module.css';

const blockClass = 'support';

export const SupportIndex: FunctionComponent = () => {
  return (
    <>
      <Breadcrumb text='バディカダイレクトの充実サポート' />

      <div className={styles[`${blockClass}__mv`]}>
        <div className={styles[`${blockClass}__mv-copy`]}>
          <h2 className={styles[`${blockClass}__mv-heading`]}>
            中古車の一生を
            <br />
            全ておまかせください。
          </h2>
          <p className={styles[`${blockClass}__mv-text`]}>クルマ選びからメンテナンス、売却まで</p>
        </div>

        <div className={styles[`${blockClass}__mv-graph`]}>
          <img
            src={'/images/support/mv.png'}
            alt='中古車も売って終わりじゃない。無料の保証・返金・点検充実サポート 安心のクルマ選びをサポートします！' />

        </div>
        <div className={styles[`${blockClass}__mv-bottom`]}>
          <p className={styles[`${blockClass}__mv-lead`]}>
            私たちはクルマを売って終わりの会社ではありません。クルマ選びから、整備・メンテナンス・売却そして次のクルマ選び。すべてを一気通貫でサポートできる体制を整えています。
          </p>
        </div>
      </div>

      <div className={styles[`${blockClass}__support`]}>
        <div className={styles[`${blockClass}__support-main`]}>
          <img src={'/images/support/img_01.png'} alt='' className={styles[`${blockClass}__support-main-img`]} />
        </div>
        <div className={styles[`${blockClass}__support-heading`]}>
          <h3 className={styles[`${blockClass}__support-heading-head`]}>
            SUPPORT <strong>01</strong>
          </h3>
          <h4 className={styles[`${blockClass}__support-heading-title`]}>クルマの購入</h4>
          <p className={styles[`${blockClass}__support-heading-text`]}>中古車のリスクをカバーする保証。</p>
        </div>
        <div className={styles[`${blockClass}__plan`]}>
          <div className={styles[`${blockClass}__plan-detail`]}>
            <ul className={styles[`${blockClass}__plan-list`]}>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_1_1.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text`]}>30日間返金保証</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    基本的に中古車はクーリングオフの対象外となりますが、バディカダイレクトでは到着後30日以内なら返品可能です。
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='/return-policy/'>
                      利用規約はこちら
                      <img src={'/images/icons/ic_arrow_right.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_1_2.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text`]}>初期不良無償対応</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    12か月点検（26項目）を整備完了した状態で納車しておりますが、万が一納車から1か月以内に不具合が発現した場合は初期不良として無償で対応いたします。
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='/initial-defect-support/'>
                      利用規約はこちら
                      <img src={'/images/icons/ic_arrow_right.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles[`${blockClass}__support`]}>
        <div className={styles[`${blockClass}__support-main`]}>
          <img src={'/images/support/img_02.png'} alt='' className={styles[`${blockClass}__support-main-img`]} />
        </div>
        <div className={styles[`${blockClass}__support-heading`]}>
          <h3 className={styles[`${blockClass}__support-heading-head`]}>
            SUPPORT <strong>02</strong>
          </h3>
          <h4 className={styles[`${blockClass}__support-heading-title`]}>メンテナンス</h4>
          <p className={styles[`${blockClass}__support-heading-text`]}>
            実際に走行していて気になる点や
            <br />
            不備があった場合も安心のサポート。
          </p>
        </div>
        <div className={styles[`${blockClass}__plan`]}>
          <div className={styles[`${blockClass}__plan-detail`]}>
            <ul className={styles[`${blockClass}__plan-list`]}>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_2.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text`]}>1か月点検</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    ご希望の方には納車から約1か月後に整備士がご希望の場所まで点検に伺います！実際に乗車して気になった点などお気軽にご相談ください。
                  </p>
                  <div className={styles[`${blockClass}__plan-listItem-option`]}>
                    <p className={styles[`${blockClass}__plan-listItem-option-heading`]}>点検内容</p>
                    <ul className={styles[`${blockClass}__plan-listItem-option-list`]}>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>ブレーキペダルの遊び</li>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>
                        パーキングブレーキの引きしろ（踏みしろ）
                      </li>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>
                        エンジンやエンジンオイルの状態
                      </li>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>
                        トランスミッション、トランスファの油量、オイル漏れの有無
                      </li>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>
                        ステアリングギヤボックスやパワーステアリング機構、デファレンシャルギアのオイル漏れの有無
                      </li>
                      <li className={styles[`${blockClass}__plan-listItem-option-listItem`]}>ブレーキ配管の状態</li>
                    </ul>
                    <p className={styles[`${blockClass}__plan-listItem-option-bom`]}>など</p>
                  </div>
                </div>
              </li>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <label className={styles[`${blockClass}__plan-listItem-label`]}>オプション</label>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_2_1.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text-green`]}>おウチで整備＆洗車パック</p>
                  <p className={styles[`${blockClass}__plan-listItem-subtext`]}>※セイビー提供サービス</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    プラン加入者には整備士がご自宅に定期的にお伺いして、クルマの状態をチェックしメンテナンス・洗車を実施します。
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='https://buddica.direct/pdf/ouchi_pack.pdf' target='_blank'>
                      おウチで整備＆洗車パックについて
                      <img src={'/images/icons/ic_win.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <label className={styles[`${blockClass}__plan-listItem-label`]}>オプション</label>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_2_2.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text`]}>6か月間保証</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    加入者限定で6ヶ月間、業界最多水準の237項目すべての不具合を0円で修理します。お預かり中の代車費用も0円になります。
                    <br />
                    ※カーセンサー保証に準ずる
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='https://www.buddica.jp/insurance/' target='_blank'>
                      料金・保証範囲はこちら
                      <img src={'/images/icons/ic_win.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles[`${blockClass}__support`]}>
        <div className={styles[`${blockClass}__support-main`]}>
          <img src={'/images/support/img_03.png'} alt='' className={styles[`${blockClass}__support-main-img`]} />
        </div>
        <div className={styles[`${blockClass}__support-heading`]}>
          <h3 className={styles[`${blockClass}__support-heading-head`]}>
            SUPPORT <strong>03</strong>
          </h3>
          <h4 className={styles[`${blockClass}__support-heading-title`]}>車検</h4>
          <p className={styles[`${blockClass}__support-heading-text`]}>面倒な車検もお家でできます！</p>
        </div>
        <div className={styles[`${blockClass}__plan`]}>
          <div className={styles[`${blockClass}__plan-detail`]}>
            <ul className={styles[`${blockClass}__plan-list`]}>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <label className={styles[`${blockClass}__plan-listItem-label`]}>オプション</label>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_3.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text-green`]}>出張車検</p>
                  <p className={styles[`${blockClass}__plan-listItem-subtext`]}>※セイビー提供サービス</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    プロの整備士が自宅に訪問し、点検から整備・車検まで行います。代車手配や待ち時間なしで自宅にいながらラクラク車検取得できます！
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='https://seibii.co.jp/services/shaken' target='_blank'>
                      詳細はこちら
                      <img src={'/images/icons/ic_win.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles[`${blockClass}__support`]}>
        <div className={styles[`${blockClass}__support-main`]}>
          <img src={'/images/support/img_04.png'} alt='' className={styles[`${blockClass}__support-main-img`]} />
        </div>
        <div className={styles[`${blockClass}__support-heading`]}>
          <h3 className={styles[`${blockClass}__support-heading-head`]}>
            SUPPORT <strong>04</strong>
          </h3>
          <h4 className={styles[`${blockClass}__support-heading-title`]}>万が一のトラブル</h4>
          <p className={styles[`${blockClass}__support-heading-text`]}>
            トラブルが起こっても整備士が
            <br />
            ご自宅までお伺いします！
          </p>
        </div>
        <div className={styles[`${blockClass}__plan`]}>
          <div className={styles[`${blockClass}__plan-detail`]}>
            <ul className={styles[`${blockClass}__plan-list`]}>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <label className={styles[`${blockClass}__plan-listItem-label`]}>オプション</label>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_4.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text-green`]}>出張整備</p>
                  <p className={styles[`${blockClass}__plan-listItem-subtext`]}>※セイビー提供サービス</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    事前に金額を確定してから伺うので、それ以上の請求はありません。担当整備士と直接お話しできるので、ご不明点などお気軽にご相談ください。
                  </p>
                  <p className={styles[`${blockClass}__plan-listItem-morelink`]}>
                    <Link to='https://seibii.co.jp/' target='_blank'>
                      詳細はこちら
                      <img src={'/images/icons/ic_win.svg'} alt='arrow-right' />
                    </Link>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles[`${blockClass}__support`]}>
        <div className={styles[`${blockClass}__support-main`]}>
          <img src={'/images/support/img_05.png'} alt='' className={styles[`${blockClass}__support-main-img`]} />
        </div>
        <div className={styles[`${blockClass}__support-heading`]}>
          <h3 className={styles[`${blockClass}__support-heading-head`]}>
            SUPPORT <strong>05</strong>
          </h3>
          <h4 className={styles[`${blockClass}__support-heading-title`]}>愛車の売却</h4>
          <p className={styles[`${blockClass}__support-heading-text`]}>
            おうちにいながら写真を
            <br />
            送るだけで査定が完了。
          </p>
        </div>
        <div className={styles[`${blockClass}__plan`]}>
          <div className={styles[`${blockClass}__plan-detail`]}>
            <ul className={styles[`${blockClass}__plan-list`]}>
              <li className={styles[`${blockClass}__plan-listItem`]}>
                <div className={styles[`${blockClass}__plan-listItem-heading`]}>
                  <div className={styles[`${blockClass}__plan-listItem-icon`]}>
                    <img src={'/images/support/plan_icon_5.svg'} alt='icon' />
                  </div>
                  <p className={styles[`${blockClass}__plan-listItem-text`]}>LINEで買取査定</p>
                </div>
                <div className={styles[`${blockClass}__plan-listItem-detail`]}>
                  <p className={styles[`${blockClass}__plan-listItem-description`]}>
                    一切電話なし、最短10分、一発提示で足元を見ない愛車の査定を行います。ご希望があれば、クルマの引き上げも私たちの提携会社にて行います。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles[`${blockClass}__thought`]}>
        <div className={styles[`${blockClass}__thought-main`]}>
          <img src={'/images/support/img_thought_1.png'} alt='' className={styles[`${blockClass}__thought-main-img`]} />
        </div>

        <p className={styles[`${blockClass}__thought-heading`]}>バディカダイレクトの思い</p>
        <p className={styles[`${blockClass}__thought-text`]}>
          昨今の自動車業界ではユーザーが不安になるような報道が連続し、クルマ選びが楽しいものではなくなっています。
          <br />
          <br />
          お店に行くと、どんな担当が付くかも分からず、数年に一度の買い物なのに、諸費用が適切なのかも分からない。即決を求められて、長時間拘束されて帰らせてくれないのではないか？ダマされていないか、考えないといけないなんて、悲しすぎます。クルマ選びは、本来は楽しいものなんです。
        </p>
        <p className={styles[`${blockClass}__thought-lead`]}>新車を作らず、愛車を作ろう</p>
        <p className={styles[`${blockClass}__thought-text`]}>
          クルマは人生を豊かにするものです。
          <br />
          「愛車」を世の中にお届けすることが、我々バディカダイレクトの使命です。
          <br />
          <br />
          ぜひリラックスできるホーム空間で、他社と比較しながら楽しいクルマ選びをしてください。
        </p>
        <div className={styles[`${blockClass}__thought-main`]}>
          <img src={'/images/support/img_thought_2.png'} alt='' className={styles[`${blockClass}__thought-main-img`]} />
        </div>
      </div>

      <div className={styles[`${blockClass}__bottom`]}>
        <div className={styles[`${blockClass}__bottom-main`]}>
          <img src={'/images/support/img_3pdf.png'} alt='' className={styles[`${blockClass}__bottom-main-img`]} />
        </div>
        <p className={styles[`${blockClass}__bottom-text`]}>
          バディカダイレクトでは、中古車の売買に役立つ3つの資料を公開しています。
          <br />
          <br />
          ★高く売る方法
          <br />
          ★安く買う方法
          <br />
          ★車選び初心者の3ステップ
          <br />
          <br />
          完全に無料です。
          <br />
          LINE追加して、ぜひご覧になってください。
        </p>
        <h5 className={styles[`${blockClass}__bottom-heading`]}>
          中古車を「高く売る方法」「安く買う方法」
          <br />
          「クルマ選び初心者の3ステップ」はこちら
        </h5>
        <div className={styles[`${blockClass}__bottom-detail`]}>
          <TrackableLink
            to='/add_line_friend'
            className={styles[`${blockClass}__line-button`]}
            analyticsData={{ eventName: 'click_support_bottom_button' }}>

            <img src={'/images/ainakano/line_icon.svg'} alt='line' />
            <span>無料で資料を手に入れる</span>
          </TrackableLink>
        </div>
      </div>
    </>);

};