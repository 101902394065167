import type { FunctionComponent } from 'react';
import styles from '~/styles/page/searchConditionShow/search-condition-show.module.css';

const NO_VALUE = 'no_value';

type Props = {
  value: number | null;
  onChange: (value: number | null) => void;
};
export const MinCarModelYearBeforeYearSelect: FunctionComponent<Props> = ({ value, onChange }) => {
  const options = [...Array(10)].
  map((_, i) => i + 1).
  map((value) => ({ value, label: `${value.toLocaleString()}年以内` }));

  return (
    <select
      value={value || undefined}
      onChange={(e) => {
        const stringValue = e.target.value;
        if (stringValue === NO_VALUE) {
          onChange(null);
          return;
        }

        onChange(Number(e.target.value));
      }}
      className={styles.select}>

      <option value={NO_VALUE}></option>
      {options.map((option) =>
      <option key={option.value} value={option.value}>
          {option.label}
        </option>
      )}
    </select>);

};