import liff from '@line/liff';
import { useLocation, useNavigate } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { CarCardImageNegotiation } from '~/components/shared/CarCardImage/CarCardImageNegotiation';
import { CarCardImageNormal } from '~/components/shared/CarCardImage/CarCardImageNormal';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { useAnalytics, useAuth, useFavorite } from '~/hooks';
import {
  useDeliveryAddressContext,
  useErrorFlashContext,
  useInfiniteScrollContext,
  useLineFavoriteModalContext } from
'~/providers';
import styles from '~/styles/page/cars/carsSearch/two-column-car-card.module.css';
import { isNewPublished, priceNumber, priceText, shortCarModelYear } from '~/utils/carStock';

const blockClass = 'two-column-car-card';

const TwoColumnCardCarStockFragment = graphql(`
  fragment TwoColumnCarCard_carStock on CarStock {
    id
    ulid
    carModelYear
    mileageKm
    makeName
    carModelName
    gradeName
    totalPaymentAmount
    salesStatus
    publishedAt
    imageUrls
    oneClickContractCounts
    tags {
      id
      name
    }
    landTransportCosts {
      cost
      prefectureCode
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof TwoColumnCardCarStockFragment>;
};
export const TwoColumnCarCard: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(TwoColumnCardCarStockFragment, props.carStock);
  const { user } = useAuth();
  const { favorite } = useFavorite();
  const { open: openLineFavoriteModal } = useLineFavoriteModalContext();
  const { open: openErrorFlash } = useErrorFlashContext();
  const location = useLocation();
  const { sendTrackEvent } = useAnalytics();
  const { storeElementId } = useInfiniteScrollContext();
  const { prefectureCode } = useDeliveryAddressContext();
  const navigate = useNavigate();
  const favoritedCarStockIds = user?.favoritedCarStocks?.map((favoritedCarStock) => favoritedCarStock.id) || [];
  const isFavorited = favoritedCarStockIds.includes(carStock.id);
  const elementId = `car-stock-${carStock.ulid}`;
  const tag = carStock.tags?.[0]?.name;
  const landTransportCost = prefectureCode ?
  carStock.landTransportCosts?.find((cost) => cost.prefectureCode === prefectureCode)?.cost :
  undefined;

  const handleClickFavorite = async () => {
    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'small-car-card'
    });

    if (isAuthError) {
      openLineFavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const handleClickCard = () => {
    storeElementId(location.key, elementId);
    sendTrackEvent('click_small_car_card', { car_stock_id: carStock.id });
    if (liff.isInClient()) {
      navigate(`/cars/${carStock.ulid}`);
    } else {
      window.open(`/cars/${carStock.ulid}`);
    }
  };

  const carCardImageProps = {
    alt: `car${carStock.ulid}`,
    isFavorite: isFavorited,
    src: carStock.imageUrls?.[0],
    handleFavorite: handleClickFavorite
  };

  return (
    <div
      className={[styles[blockClass], carStock.salesStatus === 'CLOSED' ? styles['is-soldout'] : ''].join(' ')}
      id={elementId}
      onClick={handleClickCard}>

      <div className={styles[`${blockClass}__image`]}>
        <picture className={styles[`${blockClass}__image-picture`]}>
          {carStock.oneClickContractCounts ?
          <CarCardImageNegotiation {...carCardImageProps} count={carStock.oneClickContractCounts} /> :

          <CarCardImageNormal {...carCardImageProps} />
          }
        </picture>
        {carStock.salesStatus === 'CLOSED' ?
        <p className={styles[`${blockClass}__image-soldout`]}>SOLD OUT</p> :
        isNewPublished(carStock.publishedAt) ?
        <p className={styles[`${blockClass}__image-new`]}>NEW</p> :

        tag && <p className={styles[`${blockClass}__image-tag`]}># {tag}</p>
        }
      </div>
      <p className={styles[`${blockClass}__name`]}>
        {[carStock.makeName, carStock.carModelName, carStock.gradeName].join(' ')}
      </p>
      <p className={styles[`${blockClass}__image-price`]}>
        {carStock.totalPaymentAmount ?
        <>
            <span className={styles[`${blockClass}__image-price__text`]}>総額</span>
            <span className={styles[`${blockClass}__image-price__number`]}>
              {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
            </span>
            {priceText(carStock.totalPaymentAmount, landTransportCost)}
          </> :

        <span>価格はお問い合わせください</span>
        }
      </p>
      <div className={styles[`${blockClass}__features`]}>
        <div className={styles[`${blockClass}__features-wrapper`]}>
          <div className={styles[`${blockClass}__features-item`]}>
            <span className={styles[`${blockClass}__features-item__icon`]}>年</span>
            <span className={styles[`${blockClass}__features-item__text`]}>
              {shortCarModelYear(carStock.carModelYear) || 'ー'}
            </span>
          </div>
          <div className={styles[`${blockClass}__features-item`]}>
            <span className={styles[`${blockClass}__features-item__icon`]}>走</span>
            <span className={styles[`${blockClass}__features-item__text`]}>
              {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}km
            </span>
          </div>
        </div>
      </div>
    </div>);

};