import { useInViewport } from '@mantine/hooks';
import { useEffect, type FunctionComponent } from 'react';
import { useQuery } from 'urql';
import { BottomNav, MembersSection, ReviewSection, YoutubeSection } from '~/components/shared';
import { TelConsultationBanner } from '~/components/shared/banners';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { CarSalesStatusEnum, type CarsIndex_CarStockFragment } from '~/gql/generated/graphql';
import { useDeliveryAddressContext, useWordPressContext } from '~/providers';
import styles from '~/styles/page/cars/carsIndex/cars-index.module.css';
import { CarFindSection } from './CarFindSection';
import { CarsMovies } from './CarMovies';
import { CarsSection } from './CarsSection';
import type { CmsItemsType } from './CmsSection';
import { CmsSection } from './CmsSection';
import { FirstView } from './FirstView';
import { SpecialImagesSection } from './SpecialImagesSection';

const FETCH_CAR_STOCKS_COUNT = 10;
const blockClass = 'cars-index';

const closedCarStocksIndexComponentQueryDocument = graphql(`
  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {
    carStocks(first: $first, salesStatus: $salesStatus) {
      edges {
        node {
          id
          landTransportCost(prefectureCode: $prefectureCode)
          ...CarsIndex_carStock
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const hasVideoCarStocksIndexComponentQueryDocument = graphql(`
  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {
    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {
      edges {
        node {
          id
          ...CarsIndex_carStock
        }
      }
    }
  }
`);

const CarCardGroupCarStocksFragment = graphql(`
  fragment CarsIndex_carStock on CarStock {
    id
    ...CarCardGroup_carStock
    ...CarIndex_CarMovies_carStock
    ...CarsSection_carStock
  }
`);

type Props = {
  newCarStocks: FragmentType<typeof CarCardGroupCarStocksFragment>[];
  interviewCmsItems: CmsItemsType;
};

export const CarsIndex: FunctionComponent<Props> = (props) => {
  const newCarStocks = useFragment(CarCardGroupCarStocksFragment, props.newCarStocks);
  const { newsJson, specialJson, magazineJson, fetchnewsJson, fetchSpecialJson, fetchMagazineJson } =
  useWordPressContext();
  const { ref, inViewport } = useInViewport();
  const { prefectureCode } = useDeliveryAddressContext();
  const [{ data, fetching }] = useQuery({
    query: closedCarStocksIndexComponentQueryDocument,
    variables: {
      salesStatus: CarSalesStatusEnum.Closed,
      first: FETCH_CAR_STOCKS_COUNT,
      prefectureCode: prefectureCode
    },
    pause: inViewport
  });
  const closedCarStocks = (data?.carStocks?.edges?.map((edge) => edge?.node).filter((node) => !!node) ||
  []) as CarsIndex_CarStockFragment[];
  const [hasVideoCarStocksResult] = useQuery({
    query: hasVideoCarStocksIndexComponentQueryDocument,
    variables: {
      salesStatus: CarSalesStatusEnum.OnSale,
      first: 10,
      prefectureCode: prefectureCode,
      hasVideo: true
    }
  });
  const hasVideoCarStocks = (hasVideoCarStocksResult.data?.carStocks?.edges?.
  map((edge) => edge?.node).
  filter((node) => !!node) || []) as CarsIndex_CarStockFragment[];

  useEffect(() => {
    fetchnewsJson();
    fetchSpecialJson();
    fetchMagazineJson();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FirstView />
      <div className={styles[blockClass]}>
        <TelConsultationBanner />
        <CarFindSection />
        <CarsSection title='新着のクルマ' morePath='/search?sortType=NEWER' carStocks={newCarStocks} />
        <CarsMovies carStocks={hasVideoCarStocks} />
        <CmsSection title='バディカダイレクトマガジン' morePath='/magazine' items={magazineJson || []} />
        <CmsSection title='お客様インタビュー' items={props.interviewCmsItems} morePath='/interviews' />
        <SpecialImagesSection />
        <MembersSection />
        <div ref={ref}>
          <CmsSection title='お役立ち情報' morePath='/specials' items={specialJson || []} hiddenDate={true} />
        </div>
        <YoutubeSection />
        <ReviewSection subColor={true} />
        <CarsSection
          title='相棒が見つかったクルマ'
          morePath='/search?status=CLOSED'
          carStocks={closedCarStocks}
          isLoading={fetching} />

        <CmsSection title='ニュースリリース' morePath='/news' items={newsJson || []} />
      </div>
      <BottomNav />
    </>);

};