import { type FunctionComponent } from 'react';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import { RepairHistoryCategoryEnum } from '~/gql/generated/graphql';
import styles from '~/styles/page/cars/carsShow/car-feature.module.css';
import { displayInspectionExpiryDate, shortCarModelYear } from '~/utils/carStock';

const blockClass = 'car-feature';

const CarFeatureCarStockFragment = graphql(`
  fragment CarFeature_carStock on CarStock {
    id
    carModelYear
    mileageKm
    inspectionExpiryDate
    carStockDetail {
      id
      repairHistoryCategory
    }
    promotion {
      id
      catchCopy
      description
    }
    dealerBranch {
      id
      prefectureName
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarFeatureCarStockFragment>;
};

export const CarFeature: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarFeatureCarStockFragment, props.carStock);

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>{carStock.promotion?.catchCopy}</p>
      <p className={styles[`${blockClass}__description`]}>{carStock.promotion?.description}</p>
      <div className={styles[`${blockClass}__details`]}>
        <div className={styles[`${blockClass}__details-item`]}>
          <p className={styles[`${blockClass}__details-item__title`]}>年式</p>
          <p className={styles[`${blockClass}__details-item__text`]}>
            {shortCarModelYear(carStock.carModelYear) || 'ー'}
          </p>
        </div>
        <div className={styles[`${blockClass}__details-item`]}>
          <p className={styles[`${blockClass}__details-item__title`]}>走行距離</p>
          <p className={styles[`${blockClass}__details-item__text`]}>
            {carStock.mileageKm >= 10000 ? `${Math.floor(carStock.mileageKm / 1000) / 10}万` : carStock.mileageKm}キロ
          </p>
        </div>
        <div className={styles[`${blockClass}__details-item`]}>
          <p className={styles[`${blockClass}__details-item__title`]}>車検</p>
          <p className={styles[`${blockClass}__details-item__text`]}>
            {displayInspectionExpiryDate(carStock.inspectionExpiryDate)}
          </p>
        </div>
        <div className={styles[`${blockClass}__details-item`]}>
          <p className={styles[`${blockClass}__details-item__title`]}>修理歴</p>
          <p className={styles[`${blockClass}__details-item__text`]}>
            {carStock.carStockDetail?.repairHistoryCategory == RepairHistoryCategoryEnum.Ok ?
            'あり' :
            carStock.carStockDetail?.repairHistoryCategory == RepairHistoryCategoryEnum.Nothing ?
            'なし' :
            'ー'}
          </p>
        </div>
        <div className={styles[`${blockClass}__details-item`]}>
          <p className={styles[`${blockClass}__details-item__title`]}>地域</p>
          <p className={styles[`${blockClass}__details-item__text`]}>{carStock.dealerBranch?.prefectureName || 'ー'}</p>
        </div>
      </div>
    </div>);

};