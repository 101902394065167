import type { FunctionComponent } from 'react';
import styles from '~/styles/page/selling/after-step.module.css';
import { ConversionButton } from './ConversionButton';

export const AfterStep: FunctionComponent = () => {
  return (
    <div>
      <p className={styles.title}>ご契約後の流れ</p>
      <p className={styles.caption}>4STEPで簡単！</p>
      <ol className={styles.step}>
        <li className={styles.item}>
          <p className={styles.num}>1</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/after_step_1.svg'} alt='icon step1' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>書類のやりとり</h3>
              <p className={styles.text}>ご契約後は必要書類を確認いただき、書類提出も郵送のやりとりで完結します。</p>
            </div>
          </div>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>2</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/after_step_2.svg'} alt='icon step2' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>引き取り日の確定</h3>
              <p className={styles.text}>希望日をフォームに入力いただき、引き取り日を確定します。</p>
            </div>
          </div>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>3</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/after_step_3.svg'} alt='icon step3' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>車のお引き取り</h3>
              <p className={styles.text}>
                専門業者がお客様のご指定した場所まで車両を引き取りに行きます。引き取りの場合は鍵の受け渡しと書類原本の確認だけで完結します。
              </p>
            </div>
          </div>
        </li>

        <li className={styles.item}>
          <p className={styles.num}>4</p>
          <div className={styles.outer}>
            <div className={styles.image}>
              <img src={'/images/selling/after_step_4.svg'} alt='icon step4' />
            </div>
            <div className={styles.inner}>
              <h3 className={styles['step-title']}>お振り込み</h3>
              <p className={styles.text}>
                車両と契約書類の確認後、3営業日以内に口座へお振込みします。
                <br />
                ※ローンの残債が残ってる場合は10日前後お日にちいただきます。
              </p>
            </div>
          </div>
        </li>
      </ol>

      <div className={styles['bottom-button']}>
        <ConversionButton text='無料：愛車査定はこちら' />
      </div>
    </div>);

};