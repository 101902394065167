import { type FunctionComponent } from 'react';
import { Breadcrumb } from '~/components/shared';
import styles from '~/styles/page/returnPolicy/index.module.css';

export const ReturnPolicyIndex: FunctionComponent = () => {
  return (
    <div className={styles.main}>
      <Breadcrumb text='返品に関する特約' />
      <h1 className={styles.title}>返品に関する特約</h1>
      <p className={styles.description}>
        この返品に関する特約（以下「本特約」といいます。）は、BUDDICA・DIRECT株式会社（以下「当社」といいます）が別途定めるBUDDICA
        DIRECT利用規約の特約を定めるものです。本特約に定めのない事項は、本規約が適用されるものとし、本特約において特段の定めがない限り、本特約における用語の定義は本規約に定めるとおりとします。なお、本特約と本規約が矛盾抵触する場合は、本特約の定めを優先して適用するものとします。
      </p>

      <div className={styles.section}>
        <p className={styles['sub-title']}>対象車両</p>
        <p>納車から30日以内の国産車・輸入車（新車は対象外）</p>
      </div>

      <div className={styles.section}>
        <p className={styles['sub-title']}>適用条件</p>
        <ul className={styles.list}>
          <li className={styles.item}>総額返金（車両本体＋諸費用）※延長保証・オプションも含む</li>
          <li className={styles.item}>ローン解約手数料は返金対象外</li>
          <li className={styles.item}>納車後に以下の条件に新たに該当しないこと</li>
        </ul>
      </div>

      <div className={styles.section}>
        <p className={styles['sub-title']}>返金保証の条件</p>
        <ul className={styles.list}>
          <li className={styles.item}>納車から30日以内</li>
          <li className={styles.item}>納車後3,000km未満の走行</li>
          <li className={styles.item}>新たな傷・凹み（5cm以上）がないこと</li>
          <li className={styles.item}>新たな修復歴が付いていないこと、納車時の装備品が揃っていること</li>
          <li className={styles.item}>レース・ラリーなど特殊用途で使用していないこと</li>
          <li className={styles.item}>
            納車時と名義が変わっていないこと（お客様が名義変更の権限を持ち、登録が抹消されていないこと）
          </li>
          <li className={styles.item}>未払いの債務がないこと（自動車税・自賠責保険・違反金等）</li>
          <li className={styles.item}>抵当権・譲渡担保等により、第三者の権利が設定されていないこと</li>
          <li className={styles.item}>契約名義を問わず、自動車販売・整備業を営む事業者による購入ではないこと</li>
          <li className={styles.item}>当社への背信行為・不正行為、または社会的信用を損なう行為を行っていないこと</li>
        </ul>
      </div>

      <div className={styles.section}>
        <p className={styles['sub-title']}>注意事項</p>
        <ul className={styles.list}>
          <li className={styles.item}>本サービスをご利用の際に陸送費が発生する場合は、実費をご負担いただきます</li>
          <li className={styles.item}>下取車両の買取契約は解除されず、返還も不可となります</li>
          <li className={styles.item}>納車後に取り付けた装備や税金・車検費用は返金対象外です</li>
          <li className={styles.item}>下取車両に残債がある場合、本サービスのご利用には完済が必要となります</li>
          <li className={styles.item}>本サービスをご利用いただいた場合、次回のご利用は1年後より可能となります</li>
          <li className={styles.item}>
            以下のオプションパーツを除き、すべて返金対象となりますので、安心してご利用いただけます
          </li>
        </ul>
        <ul className={styles['sub-list']}>
          <li className={styles.item}>全塗装（デカール / ラッピング含む）</li>
          <li className={styles.item}>車高調 / スプリング</li>
          <li className={styles.item}>社外エアロパーツ</li>
          <li className={styles.item}>マフラー</li>
        </ul>
      </div>

      <div className={styles.section}>
        <p className={styles['sub-title']}>減額対応</p>
        <p style={{ marginBottom: 16 }}>以下の事象が確認された場合、査定額を減額させていただくことがあります。</p>
        <ul className={styles.list}>
          <li className={styles.item}>納車後に生じた傷・凹み・事故などの損傷</li>
          <li className={styles.item}>納車後に装備品の紛失・交換があった場合</li>
          <li className={styles.item}>納車後に改造や架装が行われた場合</li>
        </ul>
      </div>

      <p className={styles.log}>2024年1月20日 制定</p>
      <p className={styles.log}>2025年3月1日 改定</p>
      <p className={styles.log}>2025年3月4日 改定</p>
    </div>);

};